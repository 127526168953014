<template>
    <div class="answer-container py-2">
        <v-card outlined class="pa-4">
            <v-img v-if="isImage(question)" :src="imageContentBuilder(question)" class="question-img" :key="`question-${question.id}`"></v-img>
            <p v-else class="title">{{ question.descripcion }}</p>
            <v-divider></v-divider>
            <div class="answers pl-10">
                <div v-if="isMultiple" :class="{'show-like-a-flex-row': hasImages(question.respuestas)}">
                    <v-checkbox
                        v-for="(answer, i) of question.respuestas"
                        :key="i"
                        v-model="selected"
                        class="data-hj-whitelist mt-1"
                        :value="answer.orden"
                        color="primary"
                        multiple
                        hide-details
                        :readonly="isAdmin"
                        @change="setAnswer"
                    >
                        <template v-slot:label>
                            <span class="font-weight-black orange--text text--darken-4 mx-2 title answer-label">{{ `${answer.orden}) ` }}</span>
                            <v-img v-if="isImageRespuesta(answer)" :src="imageContentBuilder(answer)" :key="`answer-${answer.id}`" alt="Imagen"
                            ></v-img>
                            <span v-else>{{ answer.descripcion }}</span>
                        </template>
                    </v-checkbox>
                </div>
                <div v-else>
                    <v-radio-group v-model="selected"
                    :row="hasImages(question.respuestas)"
                    :column="!hasImages(question.respuestas)"
                    @change="setAnswer" >
                        <v-radio
                            v-for="(answer, i) of question.respuestas"
                            :key="i"
                            :value="answer.orden"
                            color="primary"
                            class="data-hj-whitelist"
                            :readonly="isAdmin"
                        >
                            <template v-slot:label>
                                <span class="font-weight-black orange--text text--darken-4 mx-2 title answer-label">{{ `${answer.orden}) ` }}</span>
                                <v-img
                                v-if="isImageRespuesta(answer)" :src="imageContentBuilder(answer)" :key="`answer-${answer.id}`" alt="Imagen"></v-img>
                                <span v-else>{{ answer.descripcion }}</span>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-btn secondary text color="grey" @click="clearAnswers" :disabled="isAdmin">{{ $t('modules.exams.assestment.answers.clearAnswersButton') }}</v-btn>
                </div>
            </div>
        </v-card>
        <div class="buttons-section pt-5">
            <v-btn
                large
                rounded
                color="primary"
                class="ml-3"
                :loading="saving"
                @click="saveBtnClick"
                :disabled="isAdmin"
            >
                {{ $t('modules.exams.assestment.answers.button') }}
            </v-btn>
            <v-checkbox
                v-model="toReview"
                :label="$t('modules.exams.assestment.answers.review')"
                color="secondary"
                class="ml-5 data-hj-whitelist"
                :readonly="isAdmin"
            ></v-checkbox>
        </div>
    </div>
</template>

<script>
    import { cloneDeep, isEqual, sortBy } from 'lodash';
    import moment from 'moment'
    import { $exams } from '../Services'

    export default {
        name: 'Answers',
        props: {
            question: {type: Object, required: true},
            currentTime: {type: Number, required: true}
        },
        data () {
            return {
                selected: [],
                saving: false,
                toReview: false,
                originalAnswers: null,
                imageContent: false,
            }
        },
        watch: {
            question (value) {
                this.selected = [];
                this.toReview = value.status.toReview;
                this.checkAnsweredQuestions();
            }
        },
        computed: {
            isMultiple () {
                return (this.question.tipoPreguntaId === 5);
            },
            isAdmin() {
                return this.$store.getters['commons/isUserAdmin'];
            },
        },
        methods: {
            isImage (answer) {
                if (answer.tipoPregunta) return !!answer?.media?.imagen  // Es una pregunta.
                else return false
            },
            hasImages (respuestas) {
                let textOnly = false;
                respuestas.forEach(function(answer) {
                    if (answer?.media?.imagen) {
                        textOnly = true;
                    }
                });
                return textOnly;
            },
            isImageRespuesta (answer) {
                // Es una pregunta.
                return !!answer?.media?.imagen;
            },
            imageContentBuilder (answer) {
                let src = 'data:image/';
                const [extension] = answer.media.descripcion.split('.').pop();
                src += `${extension.toLowerCase()};base64,${answer.media.imagen}`;
                return src;
            },
            setAnswer () {
                this.question.respuestas.forEach((answer) => {
                    if (Array.isArray(this.selected) && this.selected.includes(answer.orden)) answer.elegida = 'S';
                    else if (answer.orden === this.selected) answer.elegida = 'S';
                    else answer.elegida = 'N';
                });

                this.$emit('update:question', this.question);
            },
            saveBtnClick() {
                this.$ga.event('Examenes Live', 'Guardar respuesta', 'Examen abierto', 0)
                this.saveAnswers(false)
            },
            async saveAnswers (onlySave = false) {
                this.saving = true;
                if (!onlySave && this.withoutAnswers(this.question.respuestas)) {
                    this.displayWarningPopUp(async () => {
                        // eslint-disable-next-line no-useless-catch
                        try {
                            if (!isEqual(sortBy(this.originalAnswers), sortBy(this.question.respuestas))) await this.sendToBackend();
                        } catch (error) {
                            throw error
                        }
                        this.saving = false;
                        this.$emit('next-question', true)
                    }, () => {
                        this.saving = false;
                    });
                } else {
                    this.$emit('saving-answers', true);
                    this.question.status.toReview = this.toReview;
                    this.question.isRevisar = this.question.status.toReview ? 'S' : 'N';

                    try {
                        await this.sendToBackend();
                        this.saving = false;
                        if (!onlySave) this.$emit('next-question', true);
                        this.$emit('saving-answers', false);
                    } catch (error) {
                        this.$store.dispatch('commons/openPopUp', {
                            title: this.$t('modules.exams.assestment.popUp.errorSavingAnswer.title'),
                            actionPrimary: {
                                text: this.$t('modules.exams.assestment.popUp.errorSavingAnswer.buttonSecondary'),
                                callback: () => { this.saving = false; this.$emit('saving-answers', false); },
                            },
                            icon: { color: 'error', name: 'mdi-cancel' },
                            color: 'primary',
                        });
                    }
                }
            },
            async sendToBackend() {
                const savedQuestion = {
                    tiempoRestante: 0,
                    enunciado: null,
                };

                const {status, ...enunciado} = this.question;
                savedQuestion.enunciado = enunciado;
                savedQuestion.tiempoRestante = moment.duration(this.currentTime).asMinutes();

                try {
                    await $exams.saveAnswer(savedQuestion, this.$route.params.id, 'answers');
                    this.question.respuestas.forEach(res => { res.original = res.elegida })
                    this.question.status.answered = (this.question.respuestas.filter(answer => answer.elegida != 'N').length > 0);
                } catch (error) {
                    let tmpAnswers = [];

                    if (window.localStorage.getItem('questions')) {
                        tmpAnswers = JSON.parse(window.localStorage.getItem('questions'));
                    }

                    tmpAnswers.push(savedQuestion);
                    window.localStorage.setItem('questions', JSON.stringify(tmpAnswers));
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.exams.assestment.popUp.errorSavingAnswer.title'),
                        actionPrimary: {
                            text: this.$t('modules.exams.assestment.popUp.errorSavingAnswer.buttonSecondary'),
                            callback: () => {this.saving = false; this.$emit('saving-answers', false)},
                        },
                        icon: { color: 'error', name: 'mdi-cancel' },
                        color: 'primary',
                    });
                    throw error;
                }
            },
            withoutAnswers (answers) {
                return answers.reduce((acc, answer) => {
                    if (answer.elegida.toLowerCase() === 's') return false;
                    return acc;
                }, true);
            },
            clearAnswers () {
                this.question.respuestas.forEach((answer) => { answer.elegida = 'N' });
                this.selected = [];
            },
            checkAnsweredQuestions () {
                if (this.isMultiple) {
                    this.question.respuestas.forEach((answer) => {
                        if (answer.elegida.toLowerCase() === 's') {
                            this.selected.push(answer.orden);
                            this.question.status.answered = true;
                        }
                    });
                } else {
                    this.question.respuestas.forEach((answer) => {
                        if (answer.elegida.toLowerCase() === 's') {
                            this.selected = answer.orden;
                            this.question.status.answered = true;
                        }
                    });
                }
            },
            displayWarningPopUp (actionPrimaryCallBack, actionSecondaryCallback) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.exams.assestment.popUp.withoutAnswers.title'),
                    content: this.$t('modules.exams.assestment.popUp.withoutAnswers.content'),
                    actionPrimary: { text: this.$t('modules.exams.assestment.popUp.withoutAnswers.buttonPrimary'), callback: actionPrimaryCallBack },
                    actionSecondary: { text: this.$t('modules.exams.assestment.popUp.withoutAnswers.buttonSecondary'), callback: actionSecondaryCallback },
                    icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                    color: 'primary',
                });
            },
        },
        mounted () {
            this.originalAnswers = cloneDeep(this.question.respuestas);
            this.checkAnsweredQuestions();
        }
    }
</script>

<style lang="sass" scoped>
.show-like-a-flex-row
  width: 100%
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: flex-start
  align-items: center
  align-content: center

  v-input--checkbox
    width: fit-content

.answer-label
  font-size: 20px

.answer-container
  .v-card
    .title
      font-size: 16px
      font-weight: bold
      color: #012169

  .buttons-section
    display: flex
    flex-direction: row
    align-items: center

.question-img
  width: fit-content
  display: inline-block

</style>
